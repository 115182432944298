<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import axios from 'axios';

import Multiselect from "vue-multiselect";
import InputTag from 'vue-input-tag';
import vue2Dropzone from "vue2-dropzone";
import {saveAs} from 'file-saver';

import {
  required,
  email,
  minLength,
  //sameAs,
  maxLength,
  minValue,
  maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Member registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, DatePicker, Multiselect, InputTag, vueDropzone: vue2Dropzone },
  props:{
    id:[Number,null],
  },
  data() {
    return {
      title: "Member registration",
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Members",
          href: "/management/members"
        },
        {
          text: "Member",
          active: true
        }
      ],

      load:                 0,
      pools:                [],
      coaches:              [],
      groups:               [],
      materials:            [],
      selectedPool:         "",
      selectedCoach:        "",
      selectedGroup:        "",
      selectedMaterials:    "",
      server:               "",

      form: {
        id_pool:            "",
        id_group:           "",
        id_schedule:        "",
        id_coach:           "",
        id_team:            "",
        de_gender:          "",
        nm_member:          "",
        nm_lastName:        "",
        num_age:            0,
        num_phoneNumber:    "",
        de_email:           "",
        dt_birthday:        "",
        de_materials:       [],
        de_specialistOn:    [],
        url_photograph:     "",
        sn_parentalConsent: "",
      },
      dropzoneOptions: {
        url: window.serverPath+'dist/mvw/controllers/members.php',
        thumbnailWidth: 100,
        maxFilesize: 2.5,
        headers: { "My-Awesome-Header": "header value" },
        multipleUpload: false,
        maxFiles: 1,
        autoProcessQueue: false,
        addRemoveLinks: true,
        init: function() {
          this.on("maxfilesexceeded", file => {
            this.removeFile(file);
            Swal.fire('Ooops!!!','<p><b>You can only upload a single template file.</p>','error');
          });
        }
      },
      submitted: false,
      validToken: false,
      options:{
        format: 'YYYY-MM-DD',
      }
    };
  },
  validations: {
    form: {
      nm_member:          { required, minLength: minLength(2), maxLength: maxLength(100) },
      nm_lastName:        { required, minLength: minLength(2), maxLength: maxLength(100) },
      num_age:            { minValue:minValue(8), maxValue:maxValue(100) },
      num_phoneNumber:    { required },
      de_email:           { required, email },
      de_gender:          { required },
      dt_birthday:        { required },
      id_pool:            { required },
      id_coach:           { required },
      id_group:           { required },
    }
  },
  watch:{
    load: function(){
      let app = this;
      if(this.load == 4 ){
        this.pools.forEach(function(pool){
          if(app.form.id_pool == pool.id_pool){
            app.selectedPool = pool;
          }
        });

        this.coaches.forEach(function(coach){
          if(app.form.id_coach == coach.id_coach){
            app.selectedCoach = coach;
          }
        });

        this.getScheduleByPoolAndCoachID();
      }
    },
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    this.server = window.serverPath+'dist';
    this.getPools();
    this.getCoaches();
    //this.getGroups();
    this.getMaterials();

    if( this.$route.params.id ){
      this.getMemberByID();
    }
  },
  methods: {
    async getMemberByID(){
      //let app = this;

      Swal.fire({
        title: "Loading member data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/members.php',
        {
          params: {
            request: 'getMemberByID',
            id:this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.form.id_pool           = response.data.id_pool;
          this.form.id_coach          = response.data.id_coach;
          this.form.id_group          = response.data.id_group;
          this.form.id_schedule       = response.data.id_schedule;
          this.form.id_team           = response.data.id_team;
          this.form.de_gender         = response.data.de_gender;
          this.form.nm_member         = response.data.nm_member;
          this.form.nm_lastName       = response.data.nm_lastName;
          this.form.num_age           = response.data.num_age;
          this.form.dt_birthday       = response.data.dt_birthday+" 00:00:00Z";
          this.form.num_phoneNumber   = response.data.num_phoneNumber;
          this.form.de_email          = response.data.de_email;
          this.form_de_materials      = response.data.de_materials;
          this.form.de_specialistOn   = response.data.de_specialistOn;
          this.form.url_photograph    = response.data.url_photograph;

          this.selectedMaterials      = response.data.de_materials;

          if(response.data.sn_parentalConsent == 1){
            this.form.sn_parentalConsent = true;
          }

          this.load ++;

          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was nor possible to member data! Please load this module again.", "error");
        }
      });
    },

    async getPools(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/pools.php',
        {
          params: {
            request: 'getPools',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token'){
            Swal.close();
            this.validToken = false;
          }else{
            this.pools = response.data;
            this.load++;
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load pools data! Please load this module again.", "error");
        }
      });
    },

    async getCoaches(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/coaches.php',
        {
          params: {
            request: 'getCoaches',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.coaches = response.data;
          this.load++;
        }else{
          Swal.fire("Ooops!", "It was nor possible to load coaches data! Please load this module again.", "error");
        }
      });
    },

    async getGroups(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/groups.php',
        {
          params: {
            request: 'getGroups',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.groups = response.data;
          this.load++;
        }else{
          Swal.fire("Ooops!", "It was nor possible to load groups data! Please load this module again.", "error");
        }
      });
    },

    async getMaterials(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/materials.php',
        {
          params: {
            request: 'getMaterialsForMultipleSelect',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.materials = response.data;
          this.load++;
        }else{
          Swal.fire("Ooops!", "It was nor possible to load materials data! Please load this module again.", "error");
        }
      });
    },

    async getScheduleByPoolAndCoachID(){
      let app = this;

      if(!this.$route.params.id){
        this.$bvToast.toast(
          'Loading '+this.selectedCoach.nm_coach+' '+this.selectedCoach.nm_lastName+' coach schedules.',
          {
            title: 'Loading...',
            variant: 'info',
            solid: true
          }
        );
      }

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/schedules.php',
        {
          params: {
            request: 'getScheduleByPoolAndCoachID',
            id_pool: this.selectedPool.id_pool,
            id_coach: this.selectedCoach.id_coach,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if(response.data.length > 0){
            this.groups = response.data;
            this.load++;

            if(this.$route.params.id){
              this.groups.forEach(function(group){
                if(app.form.id_group == group.id_group){
                  app.selectedGroup = group;
                }
              });
            }else{
              this.$bvToast.toast(
                'Schedules successfully loaded.',
                {
                  title: 'Done',
                  variant: 'success',
                  solid: true
                }
              );
            }
          }else{
            this.$bvToast.toast(
              'No groups found associated to the selected pool/coach, please try again or try with another pool/coach.',
              {
                title: 'Done',
                variant: 'warning',
                solid: true
              }
            );
          }
          
        }else{
          this.$bvToast.toast(
            "Ooops!", "Seems like some error ocurr, please try again.",
            {
              title: 'Loading error',
              variant: 'error',
              solid: true
            }
          );
        }
      });
    },

    // eslint-disable-next-line no-unused-vars
    saveMember(e) {
      let app = this;

      this.submitted          = true;
      // stop here if form is invalid
      this.$v.$touch();

      this.form.id_pool       = this.selectedPool.id_pool;
      this.form.id_coach      = this.selectedCoach.id_coach;
      this.form.id_group      = this.selectedGroup.id_group;
      this.form.id_schedule   = this.selectedCoach.id_schedule;
      this.form.de_materials  = this.selectedMaterials;

      if( this.form.num_age >= 18){
        this.form.sn_parentalConsent = true;
      }

      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_member = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving the member data.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        if(this.$refs.myVueDropzone.dropzone.files.length > 0 ){
          this.$refs.myVueDropzone.processQueue();
        }else{
          axios.post(window.serverPath+'dist/mvw/controllers/members.php', {
            request: 'saveMember',
            data: this.form,
            token: this.user.token,
          })
          .then(function (response) {
            let action = "";
            if( response.data === "OK" ){
              action = "saved";
            }
            if( response.data === "UPDATED" ){
              action = "updated";
            }

            Swal.fire("Good job!", "The member is correct "+action+"!", "success")
            .then(function(response){
              if(response.isConfirmed){
                app.$router.push({ name: 'members' });
              }
            });
          })
          .catch(function (error) {
            Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
          });
        }
      }
    },

    sendingFile(file, xhr, formData){
      let app = this;
      
      file;
      xhr;
      formData;

      //Appending the request, file and data to the formData object to be send all in a single request.
      formData.append('request',  'saveMember');
      formData.append('token',    this.user.token);
      formData.append('data',     JSON.stringify(app.form) );
    },

    uploadCompleted(response){
      let app = this;
      
      console.log(response);
      if(response.status != "error"){
        Swal.close();
        Swal.fire("Good job!", "The team is correct saved!", "success")
        .then(function(response){
          if(response.isConfirmed){
            app.$router.push({ name: 'members' });
          }
        });
      }else{
        Swal.close();
        Swal.fire("Ooops!", "There was an error when trying to save this team data. Please try again.", "error");
      }
    },

    downloadTicket(){
      //let app = this;
      axios
      .get(window.serverPath+'dist'+this.form.url_photograph, {responseType: 'blob'})
      .then(response => {
          saveAs(response.data);
      })
    },

    openTicket(){
      window.open(window.serverPath+'dist'+this.form.url_photograph);
    },

    consoleThis(){
      console.log(this.form.de_materials);
    },

    selectThisOption(id){
      this.form.de_materials.forEach(function(material){
        console.log(material);
        if(material == id){
          return "selected";
        }
      });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="saveMember">
              <div class="row">
                <div class="col-sm-4">
                  <h4 class="card-title">Personal Data</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="nm_member">Member name</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_member.$error }"
                          name="nm_member"
                          id="nm_member"
                          v-model="form.nm_member"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_member.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_member.required">Member name is required.</span>
                          <span v-if="!$v.form.nm_member.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.form.nm_member.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="nm_lastName">Member last name</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_lastName.$error }"
                          name="nm_lastName"
                          id="nm_lastName"
                          v-model="form.nm_lastName"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_lastName.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_lastName.required">Member last name is required.</span>
                          <span v-if="!$v.form.nm_lastName.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.form.nm_lastName.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="num_age">Member age</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.num_age.$error }"
                          name="num_age"
                          id="num_age"
                          v-model="form.num_age"
                          value=""
                        />
                        <div v-if="submitted && $v.form.num_age.$error" class="invalid-feedback">
                          <span v-if="!$v.form.num_age.required">Member age is required.</span>
                          <span v-if="!$v.form.num_age.minValue">This minimum age is invalid. It should be between 18 and 100 years old.</span>
                          <span v-if="!$v.form.num_age.maxValue">This maximum age is invalid. It should be between 18 and 100 years old.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_gender">Gender</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_gender.$error }"
                          name="de_gender"
                          id="de_gender"
                          v-model="form.de_gender"
                        >
                          <option value="">SELECT EMPLOYEE GENDER</option>
                          <option value="FEMALE">FEMALE</option>
                          <option value="MALE">MALE</option>
                          <option value="NONE BINARY">NONE BINARY</option>
                        </select>
                        <div v-if="submitted && $v.form.de_gender.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_gender.required">Gender is required.</span>
                        </div>
                      </div>

                      <div class="form-group mb-3">
                        <label for="dt_birthday">Member Birthday</label>
                        <br />
                        <date-picker
                          v-model="form.dt_birthday"
                          :first-day-of-week="1"
                          lang="en"
                          :class="{ 'is-invalid': submitted && $v.form.dt_birthday.$error }"
                          name="dt_birthday"
                          id="dt_birthday"
                          valueType="format"
                          format="YYYY-MM-DD"
                          :value="form.dt_birthday"
                        >
                        </date-picker>
                        <div v-if="submitted && $v.form.dt_birthday.$error" class="invalid-feedback">
                          <span v-if="!$v.form.dt_birthday.required">Member hires date is required.</span>
                        </div>
                      </div>

                      <div class="form-group" v-if="form.num_age < 18">
                        <div class="form-group">
                          <b-form-checkbox v-model="form.sn_parentalConsent" switch class="mb-1">
                            <label>Do parents give parental consent to photograph or videotape the minor for advertising purposes?</label>
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <h4 class="card-title">Other member data</h4>
                  <div class="form-group">
                    <label for="num_phoneNumber">Phone number</label>
                    <input
                      v-mask="'+(##) ###-###-####'"
                      type="text"
                      class="form-control"
                      name="num_phoneNumber"
                      id="num_phoneNumber"
                      v-model="form.num_phoneNumber"
                      :class="{ 'is-invalid': submitted && $v.form.num_phoneNumber.$error }"
                    />
                    <div v-if="submitted && $v.form.num_phoneNumber.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_phoneNumber.required">Phone number is required.</span>
                    </div>
                    <!--<span class="text-muted">e.g "(xx) xxxx-xxxx"</span>-->
                  </div>

                  <div class="form-group">
                    <label for="de_email">Member email</label>
                    <input
                      type="email"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.de_email.$error }"
                      name="de_email"
                      id="de_email"
                      v-model="form.de_email"
                    >
                    <div v-if="submitted && $v.form.de_email.$error" class="invalid-feedback">
                      <span v-if="!$v.form.de_email.required">Member email is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="id_pool">Pool</label>
                    <select
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.id_pool.$error }"
                      name="id_pool"
                      id="id_pool"
                      v-model="selectedPool"
                    >
                      <option value="">SELECT POOL</option>
                      <option v-for="(pool, index) in pools" :key="index" :value="pool">{{pool.nm_pool}}</option>
                    </select>
                    <div v-if="submitted && $v.form.id_pool.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id_pool.required">Pool is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="id_coach">Coach</label>
                    <select
                      class="form-control"
                      name="id_coach"
                      id="id_coach"
                      v-model="selectedCoach"
                      :class="{ 'is-invalid': submitted && $v.form.id_coach.$error }"
                      @change="getScheduleByPoolAndCoachID"
                    >
                      <option value="">SELECT COACH</option>
                      <option v-for="(coach, index) in coaches" :key="index" :value="coach">{{coach.nm_coach + " " + coach.nm_lastName}}</option>
                    </select>
                    <div v-if="submitted && $v.form.id_coach.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id_coach.required">Coach is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="id_group">Group</label>
                    <select
                      class="form-control"
                      name="id_group"
                      id="id_group"
                      v-model="selectedGroup"
                      :class="{ 'is-invalid': submitted && $v.form.id_group.$error }"
                    >
                      <option value="">SELECT GROUP</option>
                      <option v-for="(group, index) in groups" :key="index" :value="group">{{group.schedule}}</option>
                    </select>
                    <div v-if="submitted && $v.form.id_group.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id_group.required">Group is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="de_specialistOn">Specialist on</label>
                    <input-tag
                      name="de_specialistOn"
                      id="de_specialistOn"
                      v-model="form.de_specialistOn"
                      :limit="10"
                      placeholder="10 feats separated by comma."
                      class="form-control"
                      height="10"
                    >
                    </input-tag>
                  </div>
                </div>

                <div class="col-sm-4">
                  <h4 class="card-title">Upload member picture</h4>
                  <div class="form-group">
                    <button v-if="form.url_photograph != ''" type="button" class="mt-3 btn btn-sm btn-outline-dark" @click="downloadTicket">
                      Download photograph
                      <b-badge variant="dark" class="ml-1"><i class="fas fa-download"></i></b-badge>
                    </button>
                    <button v-if="form.url_photograph != ''" type="button" class="mt-3 ml-3 btn btn-sm btn-outline-primary" v-b-modal.modal-member>
                      View photograph
                      <b-badge variant="primary" class="ml-1"><i class="far fa-eye"></i></b-badge>
                    </button>

                    <b-modal no-close-on-backdrop
                      id="modal-member"
                      title="Member photograph"
                      title-class="font-18"
                      hide-footer
                    >
                      <img :src="server+form.url_photograph" width="100%" />
                    </b-modal>

                    <label for="dropzone" :class="{ 'mb-5': form.url_photograph != '' }">&nbsp;</label>
                    <vue-dropzone
                      id="dropzone"
                      ref="myVueDropzone"
                      :use-custom-slot="true"
                      :options="dropzoneOptions"
                      :uploadOnDrop="false"
                      @vdropzone-sending="sendingFile"
                      @vdropzone-complete="uploadCompleted"
                    >
                      <div class="dropzone-custom-content">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        <h4>Click to search or Drop in here the photograph</h4>
                      </div>
                    </vue-dropzone>
                  </div>

                  <div class="form-group">
                    <label for="de_materials">Materials</label>
                    <multiselect
                      id="id_pools"
                      v-model="selectedMaterials"
                      :options="materials"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="SELECT MATERIAL(S)"
                      label="label"
                      track-by="label"
                      :preselect-first="false"
                    ></multiselect>
                    <!--
                    <select
                      multiple
                      class="form-control"
                      name="de_materials"
                      id="de_materials"
                      v-model="form.de_materials"
                      size="10"
                      @change="consoleThis"
                    >
                      <option
                        v-for="(material, index) in materials"
                        :key="index"
                        :value="material"
                      >
                        {{material.nm_material}}
                      </option>
                    </select>
                    -->
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">SAVE MEMBER</button>
                    <router-link to="/members" class="ml-3">
                      <button class="btn btn-danger" type="reset">CANCEL </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
  .vue-input-tag-wrapper {
    height: auto !important;
  }

  .vue-input-tag-wrapper .input-tag {
    background-color: #5664d2 !important;
    border: 1px solid #5664d2 !important;
    color: #fff !important;
  }

  .vue-input-tag-wrapper .input-tag .remove {
    cursor: pointer;
    font-weight: 700;
    color: #fff !important;
  }
</style>